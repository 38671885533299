.product-cat-wrap {
  margin-bottom: 70px;

  .mml-wrap {
    max-width: 1170px; // i dont understand why this number, weird
  }
  .product-cat-title {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    line-height: 1.2;
  }

  .product-cat-divide-line {
    position: relative;
    background-color: #efefef;
    margin-bottom: 40px;

    &:after {
      content: " ";
      display: block;
      width: 30px;
      height: 1px;
      background-color: #000000;
    }
  }

  .products-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }
}

.single-projection_screen, .single-projection_surfaces, .single-accessories,
.post-type-archive-projection_screen, .post-type-archive-accessories, .post-type-archive-projection_surfaces
{
  .banner-title {
    margin-top: 0!important;
    margin-bottom: 120px;
  }

  #page-banner {
    background-image: url('/wp-content/uploads/2018/01/product-detail-banner.jpg');
    padding-top: 130px;
    margin-bottom: 0;
    height: 768px;
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
  }
}

.product-intro, .product-details, .product-dimensions {
  .mml-wrap {
    max-width: 1000px;
    margin: 0 auto;
  }

  .section-title {
    font-size: 38px;
    color: #000000;
  }
}

.product-intro {
  margin-top: 50px;

  .mml-wrap {
    display: flex;

    & > div {
      width: 50%;
    }

    .right {
      padding-left: 70px;
    }

    .product-title {
      color: black;
      font-size: 32px;
      font-weight: bold;
      font-family: $font-content;
      line-height: 1.2;
    }

    .feature-img-box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icons-wrap {
      margin-top: 20px;
      display: block;
      align-items: center;

      img {
        margin: 0 auto;
        display: block;
      }
      
    }
  }
}

.product-details {
  margin-top: 80px;
  position: relative;
  padding-top: 70px;

  &:before {
    display: block;
    content: " ";
    width: 100%;
    height: 230px;
    position: absolute;
    top: 0;
    background-color: #f2f2f2;
    z-index: 0;
  }

  .mml-wrap {
    position: relative;
    index: 2;
  }
}

.color-black.divider {
  background-color: black!important;
  margin-bottom: 30px;
  margin-top: 45px;
}

.product-dimensions {
  margin-top: 80px;
}