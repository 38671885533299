.u-text-center {
    text-align: center;
}

.u-text-left {
    text-align: left;
}

.u-text-right {
    text-align:  right;
}

.u-text-bold {
    font-weight: bold;
}

.u-width-full {
    max-width: 100%!important;
}