// $ refers to html.mml-lib

& *,
& *::after,
& *::before {
    //margin: 0; Do not enable this on betheme
    //padding: 0;  Do not enable this on betheme
    box-sizing: border-box;
}

& {
    // This defines what 1rem is
    font-size: 100%; //1 rem = 16px;
}

&::selection {
    //background-color: $color-primary;
    //color: $color-white;
}

.mml-container {
    max-width: rem(1200px);
    margin: 0 auto;
}

.btn {
    display: inline-block;
    padding: $gutter-btn;
    background: $color-black;
    color: white;
    font-family: $font-heading;
    font-size: $font-size-link;
    border-radius: rem(35px);
    line-height: 1.2;
    text-decoration: none;
}

// contact form7 reset
input[type="date"], input[type="email"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], select, textarea, .woocommerce .quantity input.qty {
    box-shadow: none;
    background-image: none;
}

input[type="date"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, select:focus, textarea:focus {
    color: inherit;
    background-color: #f7f7f7!important;
}

textarea {
    resize: none;
}

.wpcf7-form {
    input[type="submit"] {
        background-color: $color-black;
        width: 100%;
        color: white;
        font-family: $font-heading;
        line-height: 1.2;
        text-transform: uppercase;

        @include mappy-bp(1000px) {
            font-size: rem(20px);
        }
    }

    .form-title {
        margin-bottom: 0;
    }

    .section-des {
        margin-bottom: rem(30px);
    }

    .ajax-loader {
        display: none;
    }
}

.mml-wrap {
    max-width: 1220px;
    margin: 0 auto;
}