.product-finder-wrap {
  height: 510px;
  box-shadow: 23px 25px 52.92px 1.08px rgba(0, 0, 0, 0.09);
  background-color: white;

  .main-tab {
    display: flex;
    background-color: #efefef;

    li {
      a {
        color: #303030;
        font-size: 16px;
        text-decoration: none;
        background-color: #efefef;
        display: inline-block;
        padding: 15px;
        border-right: 1px solid #dddddd;
        padding-left: 25px;
        padding-right: 10px;
      }
    }
  }

  .main-tab-content-wrap {
    .main-tab-content {
      display: none;
    }

    .main-tab-content .inner {
      background-color: white;
      display: flex;

      .second-tab-wrap {
        width: 280px;
        border-right: 1px solid #f7f7f7;
      }
    }
  }

  .second-tab-slider {
    &-item {
      display: block;
      color: #777777;
      font-size: 14px;
      text-decoration: none;
      padding: 15px 10px 15px 40px;
      position: relative;
      border-bottom: 1px solid #f7f7f7;
      text-decoration: none;
      position: relative;
      padding-right: 20px;

      .text-wrap {
        line-height: 1;
        box-sizing: border-box;
      }

      .icon-wrap {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:before {
        position: absolute;
        left: 25px;
        bottom: 0;
        display: block;
        content: " ";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #dddddd;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    height: 410px;
    overflow: hidden;
  }

  .second-tab-content-wrap {
    flex: 1;

    .second-tab-content {
      display: none;
      
      img {
        display: block;
      }

      .product-short-des-wrap {
        padding-left: 25px;

        .btn-learn-more {
          border-radius: 0;
          font-size: 15px;
          padding: 15px 30px;
        }

        .product-title {
          font-size: 16px;
          color: #000000;
          font-family: $font-content;
          font-weight: bold;
          margin-bottom: 10px;
        }

        ul {
          margin-bottom: 30px;
        }

        ul li {
          color: #777777;
          font-size: 14px;
        }
      }

      .inner {
        height: 450px;
        align-items: center;
        padding-left: 20px;
      }

      &:first-child {
        display: block;
      }
    }
  }

  .second-tab-wrap {
    position: relative;
    height: 460px;

    .second-tab-control-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100px;
      display: flex;
      justify-content: space-between;

      i {
        font-size: 30px;
        color: #cccccc;
      }
    }
  }

}

.product-item {
  .front {
    .img-wrap {
      height: 264px;
      display: flex;
      align-items: center;
    }

    .product-title {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
      color: #303030;
      font-size: 16px;
    }
  }

  .back {
    background-color: #cce4f2;
    padding: 30px 20px 20px 15px;
    box-sizing: border-box;
    position: relative;

    .product-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.2;
      font-family: $font-content;
      margin-bottom: 25px;
      color: #000000;
    }

    .product-feature-points {
      list-style-image: url(/wp-content/uploads/2018/01/fa-check.png);
      margin-left: 20px;
      li {
        color: #000000;
        font-size: 14px;
      }
    }

    .btn-product-detail {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 54px;
      height: 54px;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      color: #000000;
    }
  }
}
