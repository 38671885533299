@font-face {
  font-family: 'Gotham-bold';
  src: url('/wp-content/themes/betheme-child/dist/fonts/Gotham-Bold.otf');
}
@font-face {
  font-family: 'Gotham-black';
  src: url('/wp-content/themes/betheme-child/dist/fonts/Gotham-Black.otf');
}
@font-face {
  font-family: 'Gotham-light';
  src: url('/wp-content/themes/betheme-child/dist/fonts/Gotham-Light.otf');
}

html.mml-lib {
  @import "abstracts/mappy-breakpoints";
  @import "abstracts/functions";
  @import "abstracts/mixins";
  @import "abstracts/variables";

  @import "base/animations";
  @import "base/base";
  //@import "base/typography";
  @import "base/utilities";

  // import components here
  @import "components/betheme-reset";
  @import "components/flip-card";
  @import "components/product-finder";

  // import pages here
  @import "pages/product-cat";

  // import layout here
  @import "layout/header";

  // import fonts here

  background-color: #404040;
  html {
    box-sizing: border-box;
    background-color: #404040;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

$font-main: "azo-sans-web",sans-serif;

p {
  font-family: 'Gotham-light';
  font-size: 16px;

}
h1,h2,h3,h4,h5,h6{
  line-height: 1.2;
  margin: 0px;
}
a{
  text-decoration: none;
}

.btns{
  a{
    display: inline-block;
    font-family: 'Gotham-black';
    color: #404040;
    box-shadow: 0px 0px 0px 0px
     #d18f1c;
    padding: 8px 35px;
    border-radius: 50px;
    background-color: #ffbb00;
    box-shadow: 2px 1px 3px 0px
    rgba(255, 187, 0, 0.5);
    transition: all .3s;
    &:hover{
      background-color: rgb(255,231,152);
    }
  }
}

img{
  max-width: 100%;
  display: block;
}

html.mml-lib #Header #Top_bar .container #menu .menu li:last-child span{
  padding-right: 20px!important;
}

/*---滚动条默认显示样式--*/

::-webkit-scrollbar-thumb{

  background-color:#ffbb00;

  height:50px;

  outline-offset:-2px;

  // outline:2px solid #fff;

  -webkit-border-radius:4px;

  // border: 2px solid #fff;

  }

  /*---鼠标点击滚动条显示样式--*/

  ::-webkit-scrollbar-thumb:hover{

  background-color:#ffbb00;

  height:50px;

  -webkit-border-radius:4px;

  }

  /*---滚动条大小--*/

  ::-webkit-scrollbar{

  width:3px;

  height:3px;

  }

  /*---滚动框背景样式--*/

  ::-webkit-scrollbar-track-piece{

  background-color:#404040;

  -webkit-border-radius:0;

  }
.mml-row{
  display: block;
  max-width: 100%;
  width: 1220px;
  box-sizing: border-box;
  padding: 0px 10px;
  margin: 0 auto;
}
#Top_bar .menu_wrapper .menu > li  a span {
  transition: all .5s ease;
}
#Header_wrapper, #Intro{
  background-color: transparent;
  z-index: 99;
}


  .menuo-arrows #Top_bar .menu > li.submenu > a > span:not(.description)::after{
    display: none!important;
  }


#Top_bar{
  background-color: transparent;
  box-sizing: border-box;
  background-image: url('/wp-content/themes/betheme-child/dist/img/home/nav-bg.png');
  background-position: center;
    // background-color: #000!important;
    box-shadow: unset;
  // padding-top: 20px;
  &.is-sticky{
    height: 45px;
    background-color: transparent!important;
    background-image: url('/wp-content/themes/betheme-child/dist/img/home/nav-bg01.png');
    // background-image: linear-gradient(to bottom,
		// rgba(0,0,0,1) 0%,
		// #404040 100%);
    background-position: center;
    .menu_wrapper .menu > li{
      padding: 0px;
    }
    .menu_wrapper .menu > li >a span{
      padding: 12px 20px;
    }
    li#menu-item-550 a span{
      transform: translateY(5px);
    }
    .menu_wrapper .menu > li#menu-item-883 span{
      padding: 12px 0px;
    }
    .menu_wrapper .menu > li.menu-item-has-children:after{
      bottom: 0px;
    }
    .menu_wrapper .menu > li.current-menu-item:after{
      bottom: 4px;
    }
  }
  .top_bar_left.clearfix{
    display: flex;
    align-items: center;
  }

  .menu_wrapper .menu > li{
    padding: 19.5px 0px;
    position: relative;
    &.menu-item-has-children:after{
      content:'';
      display: block;
      width: 0px;
      border-top: 4px solid #ffbb00;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid transparent;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
    }
    &#menu-item-883{
      // display: none;
      &:after{
        display: none;
      }
      &:hover{
        background-image: unset;
      }
      span{
        padding: 15px 0px;
        margin-left: 30px;
      }
      img{
        display: none;
      }
    }
    &:hover{
      background-image: linear-gradient(180deg,
		#000000 0%,
		#404040 100%);
      &:after{
          border: unset;
          width: 20px;
          height: 5px;
          border-radius: 10px;
          background-color: #ffbb00;
      }
    }

    &.current-menu-item{
      background-image: linear-gradient(180deg,
		#000000 0%,
		#404040 100%);
      &:after{
          border: unset;
          width: 20px;
          height: 5px;
          border-radius: 10px;
          background-color: #ffbb00;
      }
    }



  }
  .menu_wrapper .menu > li>a{
    padding: 0px 0px;
    // transform: translateY(8px);
  }
  li#menu-item-550{
    &:after{
      display: none;
    }
    &:hover{
      background-image: unset;

    }
  }
  li#menu-item-550 a span{
    position: static;
    padding: 5px 20px;
    line-height: unset!important;
    height: auto!important;
    font-size: 16px;
    font-family: 'Gotham-black';
    background-color: #ffbb00;
    box-shadow: 2px 1px 3px 0px
      rgba(255, 187, 0, 0.5);
    border-radius: 18px;
    border: unset!important;
    color: #000;
    transform: translateY(10px);
    &:hover{
      background-color: rgb(255,231,152)!important;
      opacity: .8;
    }
  }
  li#menu-item-550>a{
    height:unset;
    line-height: unset;
  }
  .menu_wrapper .menu > li >a span{
    color: #fff;
    font-family: 'Gotham-light';
    font-size: 14px;
    line-height: 1.5;
    padding: 15px 20px;
    border-right: unset;
  }
  #logo{
    height: unset!important;
    line-height: unset!important;
    padding: 0px!important;
    @media(max-width:767px){
      margin:4px 0px!important;
    }
  }
  // .menu > li > a span:not(.description){
  //   padding: 5px 20px;
  // }

  .sub-menu{
    background-color: #404040;
	  box-shadow: 2px 1px 3px 0px
		rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    // padding: 0px 10px;
    li{
      border-bottom: 1px solid #707070;
      &:last-child{
        border-bottom: unset;
      }
    }
    li.hover{
      background-image: linear-gradient(0deg,
		#a8a8a8 0%,
		#5b5b5b 100%);
    }
    li a span{
      color: #fff;
      font-family: 'Gotham-light';
    }
  }

  @media(max-width:1240px){
    height: 60px;
    .responsive-menu-toggle {
      i{
        color: #fff;
      }
    }
  }
  @media(max-width:540px){

    .responsive-menu-toggle {
      top: 20px;
    }
  }

  @media(max-width:767px){
    background-color: transparent!important;
  }
}


.testimonials_slider.single-photo .testimonials_slider_ul li .single-photo-img img {
  width: 100%;
}

#Footer {
  background-color: #404040;
  .copyright-wrap {

  }
}

div#Subheader {
  display: none;
}

div#Content {
  padding-top: 0;
}
div#Wrapper{
  background-color: #404040;
}

#page-banner {
  height: 615px;

  .banner-title {
    margin-top: 150px;
    font-size: 52px;
    font-weight: bold;
    line-height: 1.2;
    @media(max-width:768px){

    }
  }
}

.about-us-des-wrap {
  display: flex;

  .left {
    width: 700px;
    padding-right: 14px;
    box-sizing: border-box;
  }

}

// component
.certification-slider {
  position: relative;

  .cert-prev, .cert-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
    font-size: rem(40px);
    z-index: 9999;
    color: #888888;
  }

  &__item {
    display: block;
    text-decoration: none;
    padding: 15px;
  }

  .cert-next {
    right: 0;
  }

  &__cert-name {
    font-size: rem(14px);
    color: #888888;
    margin-top: rem(10px);

    @include mappy-bp(500px) {
      font-size: rem(18px);
      margin-top: rem(30px);
    }
  }
}


.contact-wrap {
  background-color: white;
  display: flex;
  flex-wrap: wrap;

  .contact-form-notice {
    color: #777777;
    font-size: 14px;
    margin-bottom: 40px;
  }

  .left {
    background-image: url('/wp-content/uploads/2018/01/contact-information.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;

    .contact-item {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .contact-detail {
        padding-left: 15px;
        font-size: 15px;
        color: #000000;
      }

      .icon-wrap {
        i {
          color: #b5e2df;
          font-size: 38px;
        }
      }
    }
  }

  .right {
    width: 717px;
    padding: 70px 70px 70px 90px;
    box-sizing: border-box;
  }
}

.row {
  display: flex;
  margin-bottom: 10px;

  & > span {
    margin-right: 25px;
  }
}

textarea.wpcf7-form-control.wpcf7-textarea {
  height: 120px;
}

.quality-control-step {
  max-width: 1000px;
  margin: 0 auto;
}

.step-title {
  position: relative;

  .num {
    position: absolute;
    right: 0;
    color: #dddddd;
  }
}

.quality-control-wrap {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;

  & > div {
    width: 50%;
  }

  .left.text {
    padding-right: 60px;
    box-sizing: border-box;
  }
}

.home-odm-wrap {
  box-shadow: 15px 15px 40.71px 18.29px rgba(48, 48, 48, 0.08);
  position: relative;
  padding: 80px 130px;
  box-sizing: border-box;
  position: relative;
  margin-top: -140px;
  background-color: white;

  p.section-des {
    max-width: 420px;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .oem-service-pic {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .oem-item {
    text-align: center;
  }

  .home-oem-box {
    min-height: 380px;
  }
}

.product-wrap {
  position: relative;

  &:hover {
    .mask {
      transform: translateY(-80%) scale(1);
    }
  }

  .mask {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-80%) scale(0);
    width: 230px;
    height: 230px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .7);
    color: white;
    font-size: 20px;
    transition: all .5s ease;
  }
}

.quality-contol-home-wrap {
  padding: 80px 80px 0 140px;
}

#Footer {
  background-color: #5b5b5b;
  padding-top: 60px;

  .mml-wrap {
    box-sizing: border-box;
    padding: 0px 10px;
    @include mappy-bp(max-width 1220px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .need-help-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width:540px){
      flex-wrap: wrap;
      justify-content: center;
      .left{
        width: 100%;
        h2{
          font-size: 36px;
        }
      }
      .right{
        width: 100%;
        max-width: 392px;
        i{
          margin-right: 10px;
        }
        .quick-link{
          max-width: 292px!important;
        }
      }
    }
    .right {
      i{
        color: #ffbb00;
        font-size: 23px;
      }
      .quick-link {
        display: block;
        color: #fff;
        font-size: 12px;
        text-decoration: none;
        &:hover{
          color: #ffbb00;
          text-decoration: underline;
        }
        &:first-child{
          margin-bottom: 40px;
        }
        @include mappy-bp(max-width 910px) {
          display: flex;
        }
      }

    @include mappy-bp(max-width 910px) {
      display: block;

      .need-help-items {
        justify-content: center;
        @include mappy-bp(max-width 530px) {
          display: block;

          .need-help-item {
            max-width: 260px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0!important;
            padding-right: 0;

            &:first-child {
              padding-left: 0!important;
            }

            &::after {
              display: none;
            }
          }
        }
      }

      .quick-link {
        max-width: 100px;
        text-align: center;
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }

  .need-help-items {
    display: flex;

    @include mappy-bp(max-width 910px) {
      flex-wrap: wrap;

      .need-help-item {
        margin-bottom: 15px;
        &:first-child {
          padding-left: 45px!important;
        }
      }
    }
  }



  .need-help-title {
    color: #fff;
    font-size: 46px;
    font-family: 'Gotham-bold';
    text-transform: uppercase;
    margin-bottom: 35px;
    line-height: 1.2;
    span{
      color: #ffbb00;
    }
    @include mappy-bp (max-width 530px) {
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .need-help-item {
    display: flex;
    padding: 0 45px;
    position: relative;
    align-items: flex-end;

    i {
      font-size: 44px;
      color: #ffbb00;
    }

    .details-wrap {
      padding-left: 20px;
    }

    .help-item-title {
      color: #ffbb00;
      margin-bottom: 0;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 5px;
      font-family: 'Gotham-bold';
    }

    .help-detail {
      color: #fff;
      margin-bottom: 0;
      font-size: 12px;
      font-family: 'Gotham-light';
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child:after {
      border-right: 0;
      display: none;
    }


  }

}

.copyright-wrap {
  margin-top: 60px;
  background-color: #ffbb00;
  @include mappy-bp(max-width 510px) {
    margin-top: 30px;
  }

  .mml-wrap {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;

    @include mappy-bp(max-width 510px) {
      display: block;
      text-align: center;

      .copyright {
        margin-bottom: 10px;
      }
    }
  }

  .copyright {
    margin-bottom: 0;
    font-size: 12px;
    color: #fff;
    font-family: 'Gotham-light';
  }

  .social-icon-wrap {
    a {
      display: inline-block;
      padding: 5px 7px;
      color: #FFA900;
      i {
        color: #FFA900;
        font-size: 20px;
      }
    }
  }
}



  &:last-child {
    margin-bottom: 0;
  }

  &:last-child:after {
    display: none;
  }

  .icon-wrap {
    text-align: center;
  }

  i {
    color: #aaaaaa;
    font-size: 15px;
  }
}

div#page-banner {
  margin-bottom: -200px;
}

h2.section-heading.u-text-center {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 30px;
  font-family: "nimbus-sans",sans-serif;
  position: relative;

  &:after {
    display: block;
    content: " ";
    width: 35px;
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 1px;
    background-color: #303030;
  }
}

.banner-title {
  font-family: $font-main;
}

.contact-form-title {
  color: #323030;
  font-size: 32px;
  font-weight: bold;
  max-width: 550px;
  line-height: 1.3;
  font-family: $font-main;
  margin-bottom: 40px;
}

.wpcf7 {
  max-width: 490px;
}

input.wpcf7-form-control.wpcf7-submit {
  max-width: 150px;
  border-radius: 0;
  font-size: 15px;
  color: white;
}

.step-title {
  font-size: 52px;
  font-family: $font-main;
  font-weight: bold;
  line-height: 1.5;
}

.divider {
  height: 1px;
  width: 35px;
  background-color: #eaedee;
  margin-top: 30px;
  margin-bottom: 20px;
}

h2.banner-title {
  text-transform: uppercase;
}

.incoming-inspection {
  margin-bottom: 30px;
}

.before-packing {
  margin-bottom: 25px;
}

div#home-banner {
  height: 790px;
  position: relative;

  .home-banner-des {
    margin-top: 460px;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
  }
}

.home-banner-des {
  font-size: 52px;
  color: white;
  font-weight: bold;
  line-height: 1.2;
}

.section-title {
  font-size: 52px;
  font-weight: bold;
  font-family: $font-main;
}

a.btn.btn-our-service {
  border-radius: 0;
  background-color: white;
  border: 1px solid black;
  color: black;
}

.home-odm-wrap.valign-top.clearfix {
  padding-bottom: 40px;
}

// home page only stuff
.setion-title {
  color: #191919;
  font-size: 52px;
  font-weight: bold;
  line-height: 1.2;
}

.product-wrap .section-des {
  font-size: 32px;
  color: #191919;
  line-height: 1;
}

.product-wrap {
  .section-title, .setion-title {
    margin-bottom: 0;
  }
}

.product-wrap.quality-contol-home-wrap p {
  font-size: 14px;
  color: #777777;
  margin-top: 30px;
  margin-bottom: 45px;
}

a.btn.btn-our-service {
  background-color: black!important;
  color: white!important;
}

div#home-blog {
  margin-top: 80px;
}

.blog-container {
  box-sizing: border-box;
  //border: 40px solid #f7f6f6;
  //margin-left: 150px;
  position: relative;
  padding-top: 90px;
  max-width: 1200px;
  margin: 0 auto;

  &::before {
    content: " ";
    display: block;
    border: 40px solid #f7f6f6;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    max-width: 1050px;
    box-sizing: border-box;
  }

  .home-blog-title {
    position: absolute;
    font-size: 58px;
    font-family: "azo-sans-web", sans-serif;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 0;
    top: 135px;
    z-index: 99;
    left: 150px;
  }
}

.blog-post-item {
  .inner {
    display: flex;
  }
}

.home-blog-slider-wrap {
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 90px;
  z-index: 9;

  .post-item-blog-title {
    font-size: 20px;
  }

  .inner {
    .right {
      padding-top: 250px;
      box-sizing: border-box;
      padding-left: 100px;
      flex: 1;
      padding-right: 60px;
    }
  }
}

.home-blog-nav {
  position: absolute;
  right: 100px;
  bottom: 100px;
  display: flex;
  align-items: center;

  a {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 50%;
    color: black;
    align-items: center;
    text-decoration: none;
  }

  .state {
    font-size: 20px;
    color: #373737;
    margin-left: 10px;
    margin-right: 10px;
  }

}

section#home-blog {
  margin-top: 80px;
}

.page-template-event {
  #page-banner {
      background-image: url('/wp-content/uploads/2018/01/bj.jpg');
      height: auto;
      margin-bottom: 80px;
  }
  .banner-title {
    margin-top: 0!important;
    padding-top: 150px;;
  }

  .the-event {
    position: relative;
    .feature-image img {
      display: block;
    }
    .mask {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, .66);
      padding: 25px 95px 20px 95px;
      box-sizing: border-box;
    }
    .feature-blog-title {
      margin-bottom: 0;
      font-size: 52px;
      font-weight: bold;
      font-family: $font-main;
      line-height: 1.2;
    }
    .meta-row {
      display: flex;
      font-size: 16px;
      .cat-wrap {
        margin-right: 50px;
        color: #303030;
      }
      .date-wrap {
        color: #303030;
      }
    }
  }

  .feature-event-wrap {
    margin-top: 110px;
  }
}

  a.blog-post-item {
    display: block;
    text-decoration: none;
  }

  .blog-post-item .feature-img-wrap img {
    width: 593px;
    height: 593px;
  }

  a.the-event {
    display: block;
  }

} // end of mmlib


// home css
#seemax-home{
  .home-banner{
    transform: translateY(-90px);
    position: relative;
    z-index: 1;
    // background-image: url('/wp-content/themes/betheme-child/dist/img/home/images/01.jpg');
    background-position: center;
    .banner-slider{
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 1);
      height: 980px;
    }

    button{
      position: absolute;
      top: 50%;
      width: 45px;
      height: 45px;
      padding: 0px;
      text-align: center;
      border-radius: 50%;
      background-color: #ffbb00;
      color: #000;
      font-size: 18px;
      z-index: 9;
      transform: translateY(-100%);
      &:focus{
        outline: unset;
      }
      &.slick-next{
        right: 20px;
      }
      &.slick-prev{
        left: 20px;
      }
    }
  }

  .home-service,.home-quality{
    //  margin-top: -90px;
     .mml-row{
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
     }

     .img-wrap{
        width: 49%;
        max-width: 565px;
     }
     .text-wrap{
        width: 49%;
        max-width: 565px;
     }
     h2{
       font-size: 46px;
       color: #fff;
       font-family: 'Gotham-bold';
       font-weight: 700;
       span{
         color: #ffbb00;
       }
     }
     p{
       color: #fff;
       font-family: 'Gotham-light';
     }

     ul{
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
       max-width: 500px;
       margin: 40px 0px 0px 30px;
     }

     li{
       width: 48%;
       margin: 9px 0px;
       font-family: 'Gotham-bold';
       font-size: 18px;
       color: #fff;
       display: flex;
       align-items: center;
     }
     .squre{
       display: block;
       width: 50px;
       min-width: 50px;
       height: 50px;
      //  background-color: #ffbb00;
       border-radius: 50%;
       margin-right: 15px;
     }

     .btns{
       margin-left: 30px;
       margin-top: 30px;
     }

     h3{
      font-size: 30px;
      color: #fff;
      font-family: 'Gotham-light';
    }

     @media(max-width:960px){
       .mml-row{
         flex-wrap: wrap;
         justify-content: center;
       }
       ul{
         margin-left: 0px;
         margin-top: 20px;
         width: 100%;
         max-width: unset;
       }
       .img-wrap,.text-wrap{
         width: 100%;
         margin: 10px 0px;
       }
       .text-wrap{
         margin-top: 20px;
       }
       .btns{
         margin-left: 0px;
       }

     }

     @media(max-width:540px){
       h2{
         font-size: 28px;
         margin-bottom: 10px;
       }
       h3{
         font-size: 26px;
       }
       li{
         font-size: 16px;
         margin: 5px 0px;
       }
       .squre{
         min-width: 30px;
         width: 30px;
         height: 30px;
       }
     }
  }
  .home-quality{
    margin: 80px 0px;
    .btns{
      margin-left: 0px;
    }
    p{
      max-width: 400px;
    }

    @media(max-width:960px){
      .text-wrap{
        order: 2;
      }
      .img-wrap{
        order: 1;
      }
    }

  }

  .home-product{
    .sec-title{
      display: flex;
      align-items: flex-end;
    }
    h2{
      margin-right: 40px;
      color: #ffbb00;
      font-size: 46px;
      font-family: 'Gotham-bold';
    }
    span{
      display: inline-block;
      padding-bottom: 10px;
      color: #fff;
      font-family: 'Gotham-light';
    }
    ul.product-list{
      display: flex;
      justify-content: space-between;
    }
    li{
      width: 32%;
      max-width: 380px;
      margin-top: 25px;
      border-radius: 35px;
      overflow: hidden;
      &:hover{
        .pic{
          background-color: #ffbb00;
          box-shadow: 0px 0px 1px 0px
		    rgba(255, 187, 0, 0.5);
        border-radius: 35px;
        }
      }
    }

    .pic{
      transition: all .3s;
    }

    img{
      border-radius: 35px;
    }

    p{
      font-family: 'Gotham-light';
      font-size: 18px;
      color: #fff;
      max-width: 316px;
      margin: 10px auto 0px;
      text-align: center;
    }
    .btns{
      text-align: center;
      margin-top: 15px;
    }

    @media(max-width:768px){
      ul.product-list{
        flex-wrap: wrap;
        justify-content: center;
      }
      li{
        width: 100%;;
      }
      .sec-title{
        justify-content: center;
      }
      h2{
        margin-right: 10px;
      }
      span{
        padding-bottom: 5px;
      }
    }

    @media(max-width:540px){
      h2{
        font-size: 30px;
      }
      span{
        padding-bottom: 0px;
      }
    }
  }

  .home-events{
    padding: 70px 0px;
    h2{
      color: #ffbb00;
      font-size: 46px;
      font-family: 'Gotham-bold';
      margin-bottom: 25px;
    }
    section{
      display: flex;
      justify-content: space-between;
    }
    .img-wrap{
       border-radius: 35px;
       overflow: hidden;
       width: 66%;
       max-width: 776px;
       height: 100%;
    }
    .event-wrap{
      width: 32%;
      max-width: 360px;
      height: 100%;
      li{
        font-family: 'Gotham-light';
        color:#fff;
        font-size: 18px;
        padding: 5px 20px;
        border-radius: 50px;
        box-sizing: border-box;
        text-align: center;
        background-color: transparent;
        margin: 25px 0px;
        transition: all .3s;
        &:first-child{
          margin-top: 0px;
        }
        &.active{
          background-color: #ffbb00;
        }
      }
      a{
        color: #fff;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;//-webkit-box为旧的属性值，2012年最新的标准草案为flex，如果把值该为flex，将不支持多行省略号。
        -webkit-line-clamp: 1;//webkit支持此属性，并且是一个不规范的属性，没有出现在css规范的草案中。必须结合display: -webkit-box和-webkit-box-orient: vertical;属性一起使用
        -webkit-box-orient: vertical;
      }
      ul{
        position: relative;
      }
      button{
        position: absolute;
        bottom: -60px;
        width: 45px;
        height: 45px;
        padding: 0px;
        text-align: center;
        border-radius: 50%;
        background-color: #ffbb00;
        color: #000;
        font-size: 18px;
        &:focus{
          outline: unset;
        }
        &.slick-next{
          right: 0px;
        }
        &.slick-prev{
          right: 60px;
        }
      }
    }

    @media(max-width:1080px){

      .event-wrap{
        a{
          font-size: 16px;
        }
      }
    }

    @media(max-width:960px){
      h2{
        text-align: center;
      }
      section{
        flex-wrap: wrap;
        justify-content: center;
      }
      .img-wrap{
        width: 100%;
      }
      .event-wrap{
        width: 100%;
        margin-top: 30px;
      }
    }
    @media(max-width:540px){
      h2{
        font-size: 30px;
      }

    }
  }

}

// event css
#new-event{
  .page-banner{
    height: 460px;
    background-image: url('/wp-content/themes/betheme-child/dist/img/event/01-2.jpg');
    background-position: center;
    margin-top: -90px;
    .mml-row{
      display: flex;
      align-items: center;
      height: 460px;
    }
    h1{
      font-size:56px;
      color: #fff;
      font-family: 'Gotham-bold';
    }
    h2 {
      font-size: 56px;
      color: white;
      font-family: 'Gotham-bold';
      text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.16);
    }

    @media(max-width:768px){
      h1{
        font-size: 42px;
      }
    }
    @media(max-width:540px){
      height: 320px;
      .mml-row{
        height: 320px;
      }
      h1{
        font-size: 36px;
      }
    }
  }
  .event-banner{
    padding: 70px 0px;
      .mml-row{
        border-radius: 45px;
        overflow: hidden;
        position: relative;
        background-image: linear-gradient(90deg,);
      }

      h2{
        color: #fff;
        font-family: 'Gotham-bold';
        position: absolute;
        bottom: 34px;
        left: 45px;
        box-sizing: border-box;
        padding-left: 20px;
        &:before{
          content:'';
          display: block;
          width: 5px;
          height: 100%;
          background-color: #ffbb00;
          box-shadow: 0px 0px 1px 0px
            rgba(255, 187, 0, 0.5);
          border-radius: 3px;
          position: absolute;
          left: 0px;
        }
      }
      img{
        border-radius: 45px;
      }

      @media(max-width:540px){
        .mml-row{
          border-radius: 20px;
        }
        img{
          border-radius: 20px;
        }
        h2{
          bottom: 20px;
          font-size: 20px;
        }
      }
  }

  .event-main{
    padding-bottom: 70px;
    li{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 30px;
      position: relative;
    }

    .img-wrap{
      width: 48%;
      max-width: 565px;
    }
    .text-wrap{
      width: 48%;
      max-width: 565px;
    }
    h3{
      color: #fff;
      font-family: 'Gotham-bold';
      font-size: 28px;
      position: relative;
      box-sizing: border-box;
      padding-left: 25px;
      margin-bottom: 15px;
      &:before{
        content:'';
        display: block;
        width: 5px;
        height: 100%;
        background-color: #ffbb00;
        box-shadow: 0px 0px 1px 0px
          rgba(255, 187, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        left: 0px;
        top: -3px;
      }
    }

    p{
      color: #fff;
      font-family: 'Gotham-light';
      font-size: 16px;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;//webkit支持此属性，并且是一个不规范的属性，没有出现在css规范的草案中。必须结合display: -webkit-box和-webkit-box-orient: vertical;属性一起使用
      -webkit-box-orient: vertical;
    }

    img{
      border-radius: 35px;
    }
    .btns{
      text-align: right;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    .turn-page{
      text-align: center;
      margin-top:60px;
      .prev-page,.next-page{
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: #ffbb00;
        text-align: center;
        color: #404040;
        line-height: 40px;
        font-size: 22px;
        border-radius: 50%;
        margin: 5px 10px;
        box-shadow: 2px 1px 3px 0px
		  rgba(255, 187, 0, 0.5);
      }
      .prev-page{
        margin-right: 60px;
      }
      .next-page{
        margin-left: 60px;
      }
      .page{
        font-size: 16px;
        color: #fff;
        display: inline-block;
        margin: 5px 3px;
      }
    }

    @media(max-width:1080px){
      .btns{
        position: static;
        margin-top: 20px;
      }
    }

    @media(max-width:768px){
      li{
        justify-content: center;
      }
      .img-wrap{
        width: 100%;
      }
      .text-wrap{
        width: 100%;
        margin-top: 30px;
      }

      @media(max-width:540px){
        h3{
          font-size: 22px;
        }
        img{
          border-radius: 20px;
        }
      }
    }
  }
}

// event details

#event-details{

  padding:70px 0px;
  h1{
    position: relative;
    padding-left: 20px;
    font-size: 36px;
    color: #fff;
    font-family: 'Gotham-bold';
    margin-bottom: 40px;
      &:before{
        content:'';
        display: block;
        width: 5px;
        height: 100%;
        background-color: #ffbb00;
        box-shadow: 0px 0px 1px 0px
          rgba(255, 187, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        left: 0px;
      }
  }

  p{
    color: #fff;
    font-family: 'Gotham-light';
    margin-bottom: 15px;
  }

  img{
    margin: 20px 0px;
  }

  li{
    color: #fff;
    font-family: 'Gotham-light';
    font-size: 16px;
    margin-bottom: 5px;
  }

  a{
    color: #fff;
    text-decoration: underline;
    &:hover{
      color: #ffbb00;
    }
  }

  @media(max-width:540px){
    h1{
      font-size: 28px;
    }
  }


}

// projection css

#projection-screen{

  padding-bottom: 100px;

  .projection-banner{
    height: 460px;
    background-image: url('http://via.placeholder.com/1920x460');
    background-position: center;
    transform: translateY(-90px);
    position: relative;
    z-index: 2;
    .mml-row{
      padding-top: 160px;
      position: relative;
      z-index: 99999999;
      // overflow: hidden;
    }
    h1{
      color: #fff;
      font-size: 56px;
      font-family: 'Gotham-bold';
    }

    button{
      position: absolute;
      top: 50%;
      width: 25px;
      height: 25px;
      padding: 0px;
      text-align: center;
      border-radius: 50%;
      background-color: transparent;
      color: #ffbb00;
      font-size: 18px;
      z-index: 99999;
      transform: translateY(-50%);
      margin: 0px;
      box-shadow: none;
      background-image: none;
      &::after{
        display: none;
      }
      &.slick-disabled{
        color: #a8a8a8;
      }
      &:focus{
        outline: unset;
      }
      &.slick-next{
        right: 5px;
      }
      &.slick-prev{
        left: 5px;
      }
    }

    .banner-nav{
      position: relative;
      margin-top: 50px;
      z-index: 999;
      box-sizing: border-box;
      padding: 0px 30px;
      background-color: #404040;
      // display: flex;
      box-shadow: 2px 1px 3px 0px
		rgba(0, 0, 0, 0.7);

    .slick-track{
      // overflow: unset;
      display: flex;
      // align-items: center;
    }
    .slick-slide{
      height: auto;
      border-right: solid 1px #5b5b5b;
      cursor: pointer;
      &.bg{
        background-image: linear-gradient(0deg,
        #000000 0%,
        #404040 100%);
      }
      &:hover{

      }
    }
    li{
      display:inline-block;
      cursor: pointer;
      box-sizing: border-box;
      padding: 8px 15px 16px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-family: 'Gotham-light';

      height: 100%;

      span{
        // white-space:nowrap;
        position: relative;
        display: inline-block;
        &:after{
          content:'';
          display: block;
          width: 0px;
          border-top: 8px solid #ffbb00;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid transparent;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &.active{

        span{
          color: #ffbb00;

        }

      }
    }
    .submenu.cur{
      display: block!important;
    }
      @media(max-width:960px){
        height: auto;
        max-height: 360px;
        overflow-y:scroll;
      }
    }


    .submenu-wrap{
      position:relative;
      // left:0; top:49px;
      background-color: #5b5b5b;
	    box-shadow: 2px 1px 3px 0px
		rgba(0, 0, 0, 0.7);
      width: 100%;
      z-index: 9;

    }

    .submenu{
      background-color: #404040;;
      z-index:1112222;
      display:none;

      width:300px;
      text-align:center;
      height: 330px;

      overflow-y: scroll;
      overflow-x: hidden;
      li{
        width: 300px;
        padding: 8px;
        box-sizing: border-box;
        transition: all .3s;
        box-sizing: border-box;
        &.current{
          background-image: linear-gradient(0deg,
        #a8a8a8 0%,
        #747474 100%);
          section{
            display: flex;
            text-align: left;
          }
        }
      }
      a{
        font-family: 'Gotham-light';
        font-size: 16px;
        color: #fff;
        z-index: 999;
        position: relative;
      }
      section{
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-left: 300px;
        left: 0px;
        bottom: 0px;
        display: flex;
        display: none;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        // overflow-y: unset;
        overflow: hidden;
        h4{
          margin-bottom: 10px;
          color: #fff;
        }
        li{
          padding: 4px 0px;
          color: #fff;
        }
      }
      .img-wrap{
        width: 49%;
        max-width: 420px;
      }
      .text{
        width: 49%;
        max-width: 420px;
        padding: 20px;
      }

      .btns{
        margin-top: 20px;
      }
    }

    @media(max-width:540px){
      h1{
        font-size: 36px;
      }
    }


  }

  .screen-ceiling{
      margin-top: 90px;
      .img-wrap{
        border-radius: 20px 45px 20px 20px;
        // overflow: hidden;
        position: relative;
        img{
          border-radius: 20px 45px 20px 25px;
        }
      }
  }

  h2{
    position: relative;
    padding-left: 20px;
    font-size: 36px;
    color: #fff;
    font-family: 'Gotham-bold';
    margin-bottom: 30px;
    // z-index: -1;
      &:before{
        content:'';
        display: block;
        width: 5px;
        height: 100%;
        background-color: #ffbb00;
        box-shadow: 0px 0px 1px 0px
          rgba(255, 187, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        left: 0px;
      }
  }

  .list{
    display: flex;
    flex-wrap: wrap;
    >li{
      width: 24%;
      max-width: 280px;
      margin-right: 1.333%;
      margin-top: 20px;
      &:hover{
        .text-wrap{
          opacity: 1;
          left:0px;
          top: 0px;
          box-shadow: 10px 10px 10px 1px
	      	rgba(0, 0, 0, 0.4);
        }
      }
      &:nth-child(4n){
        margin-right: 0px;
      }
      li{
        font-family: 'Gotham-light';
        color: #000;
        font-size: 14px;
      }
    }
  }

  h3{
    color: #fff;
    font-family: 'Gotham-bold';
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
  }



  .text-wrap{
    width: 100%;
    height: 100%;
    position: absolute;
    // left: -20px;
    // top: 30px;
    left: -5px;
    top: 5px;
    box-sizing: border-box;
    box-shadow: 2px 1px 3px 0px
		rgba(0, 0, 0, 0.7);
	  border-radius: 20px 45px 20px 20px;
    background-color: #ffbb00;
    padding: 35px 20px 10px;
    box-shadow: 2px 1px 3px 0px
		rgba(0, 0, 0, 0.7);
    transition: all .4s;
    opacity: 0;
  }

  h4{
    font-family: 'Gotham-bold';
    font-size: 16px;
    margin-bottom: 32px;
  }

  .pic{
    width:85px;
    height: 85px;
    background-color: #fff;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid #000;
    position: absolute;
    top: 5px;
    right: 4px;
    overflow: hidden;
    img{
      border-radius: 50%!important;
    }
  }

  @media(max-width:960px){
    h1{
      text-align: center;
      margin-bottom: 30px;
    }
    .list{
      max-width: 900px;
      margin: 0 auto;
      >li{
        width: 32%;
        margin-right: 2%;
        &:nth-child(4n){
          margin-right: 2%;
        }
        &:nth-child(3n){
          margin-right: 0px;
        }
      }
    }
    .banner-nav{
      z-index: 99;
    }
    .projection-banner{
      .submenu-wrap{
        position: static;
        margin: 0 auto;
        height: 0px;
        overflow: hidden;
        transition: all .3s;
        &.accordion{
          width:100%;
          height: 300px;
          display: block;
        }

      }
      .submenu{
        display: block;
        position: static;
        width: 100%;
        li{
          width: 100%;
        }
      }
    }

    section{
      display: none!important;
    }
    #banner-nav{
      width: 100%;
      margin: 0 auto;
      &.nav-ul{
        display: block;
        >li{
          display: block;
          padding-bottom: 0px;
        }
      }
    }
  }

  @media(max-width:768px){

    .list{
      justify-content: space-between;
      max-width: 620px;
      >li{
        width: 49%;
        margin-right: 0px;
        &:nth-child(4n){
          margin-right: 0px;
        }
      }
    }
  }

  @media(max-width:480px){
    .list{
      justify-content: center;
      >li{
        width: 100%;
      }
    }
  }
}

// contact css
#contact{
  background-image: url('/wp-content/themes/betheme-child/dist/img/contact/01.jpg');
  background-position: center;
  margin-top: -90px;
  .mml-row{
    height: 800px;
    display: flex;
    align-items: center;
  }
  h1{
    font-size: 56px;
    color: #fff;
    font-family: 'Gotham-bold';
    margin-bottom: 32px;
  }
  section{
    width: 100%;
  }
  .contact-form{
    width: 100%;
    max-width: 776px;
    background-color: #404040;
    box-shadow: 2px 1px 3px 0px
      rgba(0, 0, 0, 0.16);
    border-radius: 45px;
    display: flex;
    box-sizing: border-box;
    padding: 22px 60px;
    align-items: center;
    justify-content: space-between;
  }

  .form-left{
    width: 28%;
    max-width: 180px;
  }

  .form-right{
    width: 68%;
    max-width: 352px;
  }


  li{
    position: relative;
    color: #fff;
    font-size: 12px;
    font-family: 'Gotham-light';
    box-sizing: border-box;
    padding-left: 30px;
    margin: 40px 0px;
    i{
      position: absolute;
      left: 0px;
      top: 4px;
      color: #ffbb00;
      font-size: 19px;
    }
  }

  h2{
    font-size: 18px;
    color: #fff;
    font-family: 'Gotham-bold';
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
    margin-bottom: 18px;
    &.heading {
      font-size: 58px;
      margin-bottom: 30px;
    }
    // &:before{
    //   content:'';
    //   display: block;
    //   width: 5px;
    //   height: 100%;
    //   background-color: #ffbb00;
    //   box-shadow: 0px 0px 1px 0px
    //     rgba(255, 187, 0, 0.5);
    //   border-radius: 3px;
    //   position: absolute;
    //   left: 0px;
    // }
  }

  input:not(.wpcf7-submit),textarea{
    background-color: transparent;
    border: solid 1px #707070;
    border-radius: 14px;
  }
  input:not(.wpcf7-submit){
    height: 30px;
  }

  textarea{
    height: 95px;
  }

  .row{
    justify-content: space-between;
    &.fir{
      span{
        width: 49%;
      }
    }
    >span{
      margin-right: 0px;
    }
  }

  .contact-form-notice{
    font-size: 12px;
    color: #a8a8a8;
    line-height: 1.5;
  }
  .wpcf7-submit{
    max-width: 140px;
    display: inline-block;
    font-family: 'Gotham-black';
    color: #404040;
    box-shadow: 0px 0px 0px 0px
     #d18f1c;
    padding: 8px 10px;
    border-radius: 50px;
    background-color: #ffbb00;
    box-shadow: 2px 1px 3px 0px
    rgba(255, 187, 0, 0.5);
    font-size: 16px;
    font-weight: 200;
  }

  @media(max-width:768px){
    .contact-form{
      padding: 22px;
    }
  }

  @media(max-width:680px){
    .contact-form{
      flex-wrap: wrap;
      justify-content: center;
    }
    .form-left{
      width: 100%;
      max-width: 280px;
    }
    .form-right{
      width: 100%;
      max-width: 480px;
    }
  }

  @media(max-width:540px){
    h1{
      font-size: 32px;
    }
  }

}



// product details

#product-details{
  .projection-banner{
    height: 460px;
    background-image: url('http://via.placeholder.com/1920x460');
    background-position: center;
    transform: translateY(-90px);
    position: relative;
    z-index: 2;
    .mml-row{
      padding-top: 160px;
      position: relative;
      z-index: 99999999;
      // overflow: hidden;
    }
    h1{
      color: #fff;
      font-size: 56px;
      font-family: 'Gotham-bold';
    }

    button{
      position: absolute;
      top: 50%;
      width: 25px;
      height: 25px;
      padding: 0px;
      text-align: center;
      border-radius: 50%;
      background-color: transparent;
      color: #ffbb00;
      font-size: 18px;
      z-index: 99999;
      transform: translateY(-50%);
      margin: 0px;
      box-shadow: none;
      background-image: none;
      &::after{
        display: none;
      }
      &.slick-disabled{
        color: #a8a8a8;
      }
      &:focus{
        outline: unset;
      }
      &.slick-next{
        right: 5px;
      }
      &.slick-prev{
        left: 5px;
      }
    }

    .banner-nav{
      position: relative;
      margin-top: 50px;
      z-index: 999;
      box-sizing: border-box;
      padding: 0px 30px;
      background-color: #404040;
      // display: flex;
      box-shadow: 2px 1px 3px 0px
		rgba(0, 0, 0, 0.7);

    .slick-track{
      // overflow: unset;
      display: flex;
      // align-items: center;
    }
    .slick-slide{
      height: auto;
      border-right: solid 1px #5b5b5b;
      cursor: pointer;
      &.bg{
        background-image: linear-gradient(0deg,
        #000000 0%,
        #404040 100%);
      }
      &:hover{

      }
    }
    li{
      display:inline-block;
      cursor: pointer;
      box-sizing: border-box;
      padding: 8px 15px 16px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-family: 'Gotham-light';

      height: 100%;

      span{
        // white-space:nowrap;
        position: relative;
        display: inline-block;
        &:after{
          content:'';
          display: block;
          width: 0px;
          border-top: 8px solid #ffbb00;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid transparent;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &.active{

        span{
          color: #ffbb00;

        }

      }
    }
    .submenu.cur{
      display: block!important;
    }
      @media(max-width:960px){
        height: auto;
        max-height: 360px;
        overflow-y:scroll;
      }
    }


    .submenu-wrap{
      position:relative;
      // left:0; top:49px;
      background-color: #5b5b5b;
	    box-shadow: 2px 1px 3px 0px
		rgba(0, 0, 0, 0.7);
      width: 100%;
      z-index: 9;

    }

    .submenu{
      background-color: #404040;;
      z-index:1112222;
      display:none;

      width:300px;
      text-align:center;
      height: 330px;

      overflow-y: scroll;
      overflow-x: hidden;
      li{
        width: 300px;
        padding: 8px;
        box-sizing: border-box;
        transition: all .3s;
        box-sizing: border-box;
        &.current{
          background-image: linear-gradient(0deg,
        #a8a8a8 0%,
        #747474 100%);
          section{
            display: flex;
            text-align: left;
          }
        }
      }
      a{
        font-family: 'Gotham-light';
        font-size: 16px;
        color: #fff;
        z-index: 999;
        position: relative;
      }
      section{
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-left: 300px;
        left: 0px;
        bottom: 0px;
        display: flex;
        display: none;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        // overflow-y: unset;
        overflow: hidden;
        h4{
          margin-bottom: 10px;
          color: #fff;
        }
        li{
          padding: 4px 0px;
          color: #fff;
        }
      }
      .img-wrap{
        width: 49%;
        max-width: 420px;
      }
      .text{
        width: 49%;
        max-width: 420px;
        padding: 20px;
      }

      .btns{
        margin-top: 20px;
      }
    }

    @media(max-width:540px){
      h1{
        font-size: 36px;
      }
    }
  }

  @media(max-width:960px){


    .projection-banner{
      .submenu-wrap{
        position: static;
        margin-top: 15px;
        margin-bottom: 20px;
        height: 0px;
        overflow: hidden;
        transition: all .3s;
        &.accordion{
          height: 300px;
          display: block;
        }
      }
      .submenu{
        display: block;
        position: static;
      }
      section{
        display: none!important;
      }
    }


    #banner-nav{
      width: 100%;
      margin: 0 auto;
      &.nav-ul{
        display: block;


        >li{
          display: block;
          padding-bottom: 0px;
        }
      }
    }
  }


}

html.mml-lib{
  .contact-dealer{
    padding: 70px 0px;
    background-color: #404040;
    h2{
      position: relative;
      padding-left: 20px;
      font-size: 38px;
      color: #fff;
      font-family: 'Gotham-bold';
        &:before{
          content:'';
          display: block;
          width: 5px;
          height: 100%;
          background-color: #ffbb00;
          box-shadow: 0px 0px 1px 0px
            rgba(255, 187, 0, 0.5);
          border-radius: 3px;
          position: absolute;
          left: 0px;
        }
    }

    .table-list{
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;

      overflow-y: scroll;
      ul{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      li{
        margin-top: 60px;
        width: 24%;
        text-align: center;
        &:first-child{
         .title-wrap{
           margin-top: -12px;
         }
        }
      }
      .title-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
        img{
          width: 40px;
          display: inline-block;
          margin: unset;
          height: 100%;
          margin-right: 10px;
        }
      }
      p{
        border: unset;
        color: #fff;
        font-size: 13px;
        font-family: 'Gotham-light';
        padding: 6px 10px;
        &:first-child{
          text-align: left;
        }
      }
    }

    .items{
      margin-top: 60px;
      text-align: center;
      width: 26%;
    }

    img{
      margin:0 auto;
    }
    h4{
      color: #fff;
      font-size: 18px;
      font-family: 'Gotham-bold';
      margin-top: 0px;
      margin-bottom: 0px;
    }
    table{
      tr{
        &:first-child{
          td{
            background: unset;

          }
        }
        td{
          border: unset;
          color: #fff;
          font-size: 13px;
          font-family: 'Gotham-light';
          padding: 6px 10px;
          &:first-child{
            text-align: left;
          }
        }
      }
    }

    @media(max-width:960px){
      .items{
        width: 49%;
      }
    }

    @media(max-width:540px){
      h2{
        font-size: 30px;
      }
      .table-list{
        justify-content: center;
      }
      .items{
        width: 100%;
      }
    }
  }
  .details-des{
    padding: 80px 0px;
    .mml-row{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .img-wrap{
      width: 49%;
      max-width: 565px;
    }
    .text-wrap{
      width: 49%;
      max-width: 565px;
      font-family: 'Gotham-light';
      div{
        color: #fff;
      }
    }

    .slider-for{
      position: relative;
      border-radius: 30px;
      overflow: hidden;
      img{
        border-radius: 30px;
      }
    }

    button{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 45px;
      height: 45px;
      padding: 0px;
      text-align: center;
      border-radius: 50%;
      background-color: #ccc;
      box-shadow: unset;
      color: #fff;
      font-size: 32px;
      z-index: 2;
      font-family: 'Gotham-bold';
      &:focus{
        outline: unset;
      }
      &.slick-next{
        right: 10px;
        margin:0px;
      }
      &.slick-prev{
        left: 10px;
      }
    }

    .slider-nav{
      margin-top: 5px;
      border-radius: 10px;
      overflow:hidden;
      max-height: 70px;
      li{
        border-right: 1px solid #000;
      }
    }

    h2{
      color: #fff;
      font-family: 'Gotham-bold';
      font-size: 36px;
      position: relative;
      box-sizing: border-box;
      padding-left: 25px;
      margin-bottom: 15px;
      &:before{
        content:'';
        display: block;
        width: 5px;
        height: 100%;
        background-color: #ffbb00;
        box-shadow: 0px 0px 1px 0px
          rgba(255, 187, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }

    p,li{
      color: #fff;
      font-family: 'Gotham-light';
      font-size: 14px;
    }

    strong{
      color: #fff;
      font-family: 'Gotham-blod';
      font-size: 16px;
    }

    .icons-list{
      ul{
        display: flex;
        flex-wrap: wrap;
      }

      li{
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
        margin-top: 12px;
        width: 25%;
        max-width: 100px;
      }
    }

    .download-wrap{
      border-bottom: 2px solid rgba(255,255,255,.8);
      margin-bottom: 20px;
      padding-bottom: 5px;
      text-align: right;
      a{
        font-size: 16px;
        color: #fff;
        transition: all .3s;
        &:hover{
          color: #ffbb00;
        }
      }
      i{
        margin-left: 5px;
        color: #ffbb00;
      }
    }



    @media(max-width:960px){
      .mml-row{
        flex-wrap: wrap;
        justify-content: center;
      }
      .img-wrap{
        width: 100%;
      }
      .text-wrap{
        width: 100%;
        margin-top: 40px;
      }
    }

    @media(max-width:540px){
      h2{
        font-size: 28px;
      }
    }
  }

  .details-table{
    h2{
      color: #fff;
      font-family: 'Gotham-bold';
      font-size: 36px;
      position: relative;
      box-sizing: border-box;
      padding-left: 25px;
      margin-bottom: 40px;
      &:before{
        content:'';
        display: block;
        width: 5px;
        height: 100%;
        background-color: #ffbb00;
        box-shadow: 0px 0px 1px 0px
          rgba(255, 187, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }

    .table-wrap{
      width: 100%;
      overflow-x: scroll;
      margin-bottom: 30px;
    }

    table{
      td{
        background: unset;
        border: unset;
        font-family: 'Gotham-light';
        font-size: 16px;
        color: #fff;
        padding-left: 0px;
      }

      tr{
        border-bottom: 1px solid #707070;
        td{
          &:first-child{
            text-align: left;
            font-family: 'Gotham-black';
            font-size: 16px;
            max-width: 30%;
          }
        }
      }
    }

    @media(max-width:540px){
      h2{
        font-size: 28px;
      }
    }
  }

  .details-tensioned{
    padding: 70px 0px;
    h2{
      color: #fff;
      font-family: 'Gotham-bold';
      font-size: 36px;
      position: relative;
      box-sizing: border-box;
      padding-left: 25px;
      margin-bottom: 40px;
      &:before{
        content:'';
        display: block;
        width: 5px;
        height: 100%;
        background-color: #ffbb00;
        box-shadow: 0px 0px 1px 0px
          rgba(255, 187, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }

    .pic{
      overflow-x: scroll;
      box-sizing: border-box;
      padding: 20px 0px;
      background-color: #fff;
      img{
        max-width: unset;
        margin: 0 auto;
      }
    }

    @media(max-width:540px){
      h2{
        font-size: 28px;
      }
    }
  }

  @media(max-width:960px){


    .projection-banner{
      .submenu-wrap{
        position: static;
        margin-top: 15px;
        margin-bottom: 20px;
        height: 0px;
        overflow: hidden;
        transition: all .3s;
        &.accordion{
          height: 300px;
          display: block;
        }
      }
      .submenu{
        display: block;
        position: static;
      }
      section{
        display: none!important;
      }
    }


    #banner-nav{
      width: 100%;
      margin: 0 auto;
      &.nav-ul{
        display: block;


        >li{
          display: block;
          padding-bottom: 0px;
        }
      }
    }
  }


}

html.mml-lib{
  .contact-dealer{
    padding: 70px 0px;
    background-color: #404040;
    h2{
      position: relative;
      padding-left: 20px;
      font-size: 38px;
      color: #fff;
      font-family: 'Gotham-bold';
        &:before{
          content:'';
          display: block;
          width: 5px;
          height: 100%;
          background-color: #ffbb00;
          box-shadow: 0px 0px 1px 0px
            rgba(255, 187, 0, 0.5);
          border-radius: 3px;
          position: absolute;
          left: 0px;
        }
    }

    .table-list{
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      overflow-y: scroll;
      ul{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      li{
        margin-top: 60px;
        width: 24%;
        text-align: center;
        &:first-child{
         .title-wrap{
           margin-top: -12px;
         }
        }
      }
      .title-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
        img{
          width: 40px;
          display: inline-block;
          margin: unset;
          height: 100%;
          margin-right: 10px;
        }
      }
      p{
        border: unset;
        color: #fff;
        font-size: 13px;
        font-family: 'Gotham-light';
        padding: 6px 10px;
        &:first-child{
          text-align: left;
        }
      }
    }

    .items{
      margin-top: 60px;
      text-align: center;
      width: 26%;
    }

    img{
      margin:0 auto;
    }
    h4{
      color: #fff;
      font-size: 18px;
      font-family: 'Gotham-bold';
      margin-top: 0px;
      margin-bottom: 0px;
    }
    table{
      tr{
        &:first-child{
          td{
            background: unset;

          }
        }
        td{
          border: unset;
          color: #fff;
          font-size: 13px;
          font-family: 'Gotham-light';
          padding: 6px 10px;
          &:first-child{
            text-align: left;
          }
        }
      }
    }

    @media(max-width:960px){
      .items{
        width: 49%;
      }
    }

    @media(max-width:540px){
      h2{
        font-size: 30px;
      }
      .table-list{
        justify-content: center;
      }
      .items{
        width: 100%;
      }
    }
  }
}




html.mml-lib{
  .post-type-archive-accessories,.page-template-accessories2,.post-type-archive-projection_surfaces{
    .page-banner{
      margin-bottom: 30px;
    }
    #Content{
      background-color: #404040;
    }

    #projector-lift{
      margin-top: 30px;
    }

    .product-cat-wrap .product-cat-title{
      color: #fff;
    }
  }
}
